import React, { useEffect, useRef, useState } from "react";
import styles from "./InputDate.module.scss";
import { ArrowDownIcon } from "../../../icons/ArrowDownIcon";
import { ArrowUpIcon } from "../../../icons/ArrowUpIcon";

const days = Array.from({ length: 31 }, (_, i) => i + 1);
const months = Array.from({ length: 12 }, (_, i) => i + 1);
const years = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - i,
);

interface InputDateProps {
  onDateChange: (formattedDate: string) => void;
}

export const InputDate: React.FC<InputDateProps> = ({ onDateChange }) => {
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [isDayDropdownOpen, setIsDayDropdownOpen] = useState(false);
  const [isMonthDropdownOpen, setIsMonthDropdownOpen] = useState(false);
  const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);

  const dayRef = useRef<HTMLDivElement>(null);
  const monthRef = useRef<HTMLDivElement>(null);
  const yearRef = useRef<HTMLDivElement>(null);

  const filterOptions = (options: string[], input: string) => {
    return options.filter((option) => option.startsWith(input));
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dayRef.current && !dayRef.current.contains(event.target as Node)) {
      setIsDayDropdownOpen(false);
    }
    if (monthRef.current && !monthRef.current.contains(event.target as Node)) {
      setIsMonthDropdownOpen(false);
    }
    if (yearRef.current && !yearRef.current.contains(event.target as Node)) {
      setIsYearDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      const formattedDate = `${selectedDay.padStart(2, "0")}.${selectedMonth.padStart(2, "0")}.${selectedYear}`;
      onDateChange(formattedDate);
    }
  }, [selectedDay, selectedMonth, selectedYear, onDateChange]);

  return (
    <div className={styles.container}>
      <div className={styles.titleField}>
        Дата рождения
        <span>
          {selectedDay && selectedMonth && selectedYear
            ? `${selectedDay.padStart(2, "0")}.${selectedMonth.padStart(2, "0")}.${selectedYear}`
            : "Указать"}
        </span>
      </div>
      <div className={styles.selects}>
        <div
          ref={dayRef}
          className={styles.dropdownContainer}
          onClick={() => setIsDayDropdownOpen(!isDayDropdownOpen)}
        >
          <label className={styles.dropdownLabel}>День</label>
          <div className={styles.valueAndArrow}>
            <input
              type="text"
              value={selectedDay}
              onChange={(e) => setSelectedDay(e.target.value)}
              className={styles.input}
            />
            {isDayDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
          {isDayDropdownOpen && (
            <ul className={styles.select}>
              {filterOptions(days.map(String), selectedDay).map((day) => (
                <li
                  key={day}
                  onClick={() => {
                    setSelectedDay(day);
                    setIsDayDropdownOpen(false);
                  }}
                  className={styles.select_item}
                >
                  {day}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          ref={monthRef}
          className={styles.dropdownContainer}
          onClick={() => setIsMonthDropdownOpen(!isMonthDropdownOpen)}
        >
          <label className={styles.dropdownLabel}>Месяц</label>
          <div className={styles.valueAndArrow}>
            <input
              type="text"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(e.target.value)}
              className={styles.input}
            />
            {isMonthDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
          {isMonthDropdownOpen && (
            <ul className={styles.select}>
              {filterOptions(months.map(String), selectedMonth).map((month) => (
                <li
                  key={month}
                  onClick={() => {
                    setSelectedMonth(month);
                    setIsMonthDropdownOpen(false);
                  }}
                  className={styles.select_item}
                >
                  {month}
                </li>
              ))}
            </ul>
          )}
        </div>

        <div
          ref={yearRef}
          className={styles.dropdownContainer}
          onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}
        >
          <label className={styles.dropdownLabel}>Год</label>
          <div className={styles.valueAndArrow}>
            <input
              type="text"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className={styles.input}
            />
            {isYearDropdownOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </div>
          {isYearDropdownOpen && (
            <ul className={styles.select}>
              {filterOptions(years.map(String), selectedYear).map((year) => (
                <li
                  key={year}
                  onClick={() => {
                    setSelectedYear(year);
                    setIsYearDropdownOpen(false);
                  }}
                  className={styles.select_item}
                >
                  {year}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
