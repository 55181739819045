import React, { useState, useEffect } from "react";
import styles from "./AddPhoto.module.scss";
import { PhotoIcon } from "icons/PhotoIcon";

interface AddPhotoProps {
  onPhotoChange?: (files: (File | null)[]) => void; // Optional callback
}

const AddPhoto: React.FC<AddPhotoProps> = ({ onPhotoChange }) => {
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([null, null, null, null]);
  const [previews, setPreviews] = useState<(string | ArrayBuffer | null)[]>([null, null, null, null]);

  useEffect(() => {
    if (onPhotoChange) {
      onPhotoChange(selectedFiles); // Notify parent of file changes
    }
  }, [selectedFiles, onPhotoChange]);

  const handleFileChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null; 
    if (file) {
      const newSelectedFiles = [...selectedFiles];
      newSelectedFiles[index] = file; 
      setSelectedFiles(newSelectedFiles);

      const reader = new FileReader();
      reader.onloadend = () => {
        const newPreviews = [...previews]; 
        newPreviews[index] = reader.result; 
        setPreviews(newPreviews);
      };
      reader.readAsDataURL(file); 
    }
  };

  return (
    <div className={styles.addPhotoWrap}>
      <div className={styles.addPhotoContainer}>
        {Array.from({ length: 4 }).map((_, index) => (
          <div key={index} className={styles.addPhotoItem}>
            <label htmlFor={`fileInput${index}`} className={styles.iconLabel}>
              {previews[index] ? (
                <img
                  src={previews[index] as string} 
                  alt={`Preview ${index + 1}`} 
                  className={styles.previewImage} 
                />
              ) : (
                <PhotoIcon />
              )}
            </label>
            <input
              id={`fileInput${index}`} 
              type="file" 
              accept="image/*" 
              onChange={(event) => handleFileChange(index, event)}
              className={styles.hiddenInputBtn} 
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default AddPhoto;