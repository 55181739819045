// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h1 {
  margin: 0;
}

.Title_titleWrap__hVAl- {
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
}
.Title_titleWrap__hVAl- .Title_firstTitle__BVKxE {
  margin: 0;
}
.Title_titleWrap__hVAl- .Title_lastTitle__i4edA {
  margin-top: -1px;
}`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.module.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AACA;EACE,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AAEF;AACE;EACE,SAAA;AACJ;AACE;EACE,gBAAA;AACJ","sourcesContent":["h1 {\n  margin: 0;\n}\n.titleWrap {\n  color: rgba(0, 0, 0, 1);\n  font-weight: 400;\n  font-size: 24px;\n  line-height: 32px;\n  text-align: left;\n\n\n  .firstTitle {\n    margin: 0;\n  }\n  .lastTitle {\n    margin-top: -1px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleWrap": `Title_titleWrap__hVAl-`,
	"firstTitle": `Title_firstTitle__BVKxE`,
	"lastTitle": `Title_lastTitle__i4edA`
};
export default ___CSS_LOADER_EXPORT___;
