import React, { FC } from "react";

import styles from "./Stepper.module.scss";
import classNames from "classnames";

type StepperProps = {
  currentStep: number;
};

const Stepper: FC<StepperProps> = ({ currentStep }) => {
  return (
    <div className={styles.container}>
      <span
        className={classNames(styles.step, {
          [styles.secondStep]: currentStep === 2,
          [styles.thirdStep]: currentStep === 3,
        })}
      ></span>
    </div>
  );
};

export default Stepper;
