import React from "react";
import styles from "./Logo.module.scss";

const Logo = () => {
  return (
    <div className={styles.logoWrap}>
      <p className={styles.logo}>Swiper App</p>
    </div>
  );
};

export default Logo;
