import React from "react";

export const ArrowUpIcon = ({
  width = "16",
  height = "16",
  fill = "black",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.94922C8.24023 4.94922 8.43945 5.04297 8.63281 5.23047L13.0391 9.74219C13.1855 9.89453 13.2676 10.0762 13.2676 10.293C13.2676 10.7324 12.916 11.0898 12.4824 11.0898C12.2598 11.0898 12.0605 10.9961 11.9023 10.8379L7.99414 6.83008L4.09766 10.8379C3.93945 10.9961 3.73438 11.0898 3.52344 11.0898C3.08398 11.0898 2.73242 10.7324 2.73242 10.293C2.73242 10.0762 2.80859 9.89453 2.96094 9.74219L7.36719 5.23633C7.55469 5.04297 7.75977 4.94922 8 4.94922Z"
        fill={fill}
      />
    </svg>
  );
};
