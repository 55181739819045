import React, { useState } from "react";
import classNames from "classnames";
import { LocationIcon } from "icons/locationIcon";
import { ChangeLocationIcon } from "icons/changeLocationIcon";
import style from "./InputLocation.module.scss";

interface InputLocationProps {
  onLocationChange: (value: string) => void;
}

export const InputLocation = ({ onLocationChange }: InputLocationProps) => {
  const [city, setCity] = useState("");

  const handleLocationClick = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const apiKey = "54cf0400-0692-4ea3-84af-b3c07830a295";
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;

          fetch(
            `https://geocode-maps.yandex.ru/1.x/?format=json&geocode=${longitude},${latitude}&apikey=${apiKey}`,
          )
            .then((response) => response.json())
            .then((data) => {
              const components =
                data.response.GeoObjectCollection.featureMember[0].GeoObject
                  .metaDataProperty.GeocoderMetaData.Address.Components;
              const city = components.find(
                (component) => component.kind === "locality",
              ).name;
              setCity(city);
              onLocationChange(city);
            })
            .catch((error) =>
              console.error("Error fetching city data: ", error),
            );
        },
        (error) => {
          console.error("Error obtaining geolocation: ", error);
          setCity("Не удалось получить местоположение");
          onLocationChange("Не удалось получить местоположение");
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        },
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setCity("Геолокация не поддерживается вашим браузером");
      onLocationChange("Геолокация не поддерживается вашим браузером");
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
    onLocationChange(e.target.value);
  };

  return (
    <div className={classNames(style.container)}>
      <div className={style.group}>
        <LocationIcon />
        <div className={classNames(style.text)}>
          <input
            onClick={handleLocationClick}
            placeholder="Ваше местоположение"
            value={city}
            onChange={handleCityChange}
          />
        </div>
      </div>

      <ChangeLocationIcon />
    </div>
  );
};
