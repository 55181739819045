// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputName_container__zKoqC {
  height: 60px;
  padding: 19px 16px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
}
.InputName_container__zKoqC input {
  width: 325px;
  height: 100%;
  border: none;
  outline: none;
  background-color: inherit;
  font-size: 17px;
  color: #98989e;
}
.InputName_container__zKoqC input::placeholder {
  color: #98989e;
  font-size: 17px;
}
.InputName_container__zKoqC .InputName_contentContainer__d86ft {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}
.InputName_container__zKoqC .InputName_checkIcon__bwM8m {
  border-radius: 12px;
  background-color: #34c759;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Input/InputName/InputName.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE,YAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;EACA,cAAA;AACJ;AACI;EACE,cAAA;EACA,eAAA;AACN;AAGE;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,2BAAA;EACA,aAAA;EACA,mBAAA;AADJ;AAIE;EACE,mBAAA;EACA,yBAAA;EACA,cAAA;AAFJ","sourcesContent":[".container {\n  height: 60px;\n  padding: 19px 16px;\n  background-color: #ffffff;\n  border-radius: 10px;\n  position: relative;\n\n  input {\n    width: 325px;\n    height: 100%;\n    border: none;\n    outline: none;\n    background-color: inherit;\n    font-size: 17px;\n    color: #98989e;\n\n    &::placeholder {\n      color: #98989e;\n      font-size: 17px;\n    }\n  }\n\n  .contentContainer {\n    position: absolute;\n    right: 16px;\n    top: 50%;\n    transform: translateY(-50%);\n    display: flex;\n    align-items: center;\n  }\n\n  .checkIcon {\n    border-radius: 12px;\n    background-color: #34c759;\n    color: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `InputName_container__zKoqC`,
	"contentContainer": `InputName_contentContainer__d86ft`,
	"checkIcon": `InputName_checkIcon__bwM8m`
};
export default ___CSS_LOADER_EXPORT___;
