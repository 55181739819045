import React, { useState } from "react";
import Title from "components/Title/Title";
import Button from "components/Button/Button";
import AddPhoto from "components/AddPhoto/AddPhoto";
import styles from "./RegisterStepThree.module.scss";
import Stepper from "components/Stepper/Stepper";

const RegisterStepThree: React.FC = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [photoUploaded, setPhotoUploaded] = useState<boolean>(false);

  const handleNextClick = () => {
    if (isChecked && photoUploaded) {
      // Logic for moving to the next step
      console.log("Profile created");
    } else {
      alert("Please agree to the terms and upload a photo.");
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(e.target.checked);
  };

  const handlePhotoChange = (files: (File | null)[]) => {
    setPhotoUploaded(files.some(file => file !== null)); // Check if any file is uploaded
  };

  return (
    <div className={styles.registerStepThree}>
      <Stepper currentStep={3} />
      <div className={styles.title}>
        <Title firstTitle="Добавьте фото" />
      </div>

      <div className={styles.photoContainer}>
        <AddPhoto onPhotoChange={handlePhotoChange} />
      </div>

      <div className={styles.checkboxContainer}>
        <label className={styles.checkboxLabel}>
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className={styles.customRadio}
            />
        </label>
        <div className={styles.infos}>
          <p>Я согласен с <span className={styles.privacyText}> политикой конфиденциальности</span></p>
          <p>и с <span className={styles.agreementText}> пользовательским соглашением</span></p>
        </div>
      </div>
      <div className={styles.button}>
        <Button
          title="Создать Профиль"
          onClick={handleNextClick}
          disabled={!isChecked || !photoUploaded} // Disable button if either condition is false
        />
      </div>
    </div>
  );
};

export default RegisterStepThree;