// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logo_logoWrap__VsomF {
  background: rgb(242, 242, 247);
  border-radius: 30px 30px 0 0;
}
.Logo_logoWrap__VsomF .Logo_logo__6yrBi {
  background: linear-gradient(92.62deg, #53ade9 15.9%, #27547b 100.17%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  margin: 10px auto;
  display: inline-block;
}`, "",{"version":3,"sources":["webpack://./src/components/Logo/Logo.module.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,4BAAA;AACF;AACE;EACE,qEAAA;EACA,6BAAA;EACA,qBAAA;EACA,oCAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;AACJ","sourcesContent":[".logoWrap {\n  background: rgba(242, 242, 247, 1);\n  border-radius: 30px 30px 0 0;\n\n  .logo {\n    background: linear-gradient(92.62deg, #53ade9 15.9%, #27547b 100.17%);\n    -webkit-background-clip: text;\n    background-clip: text;\n    -webkit-text-fill-color: transparent;\n    color: transparent;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 25px;\n    margin: 10px auto;\n    display: inline-block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoWrap": `Logo_logoWrap__VsomF`,
	"logo": `Logo_logo__6yrBi`
};
export default ___CSS_LOADER_EXPORT___;
