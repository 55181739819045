import React, { useState } from "react";
import style from "./InputName.module.scss";
import classNames from "classnames";
import { CheckIcon } from "icons/CheckIcon";

interface InputProps {
  placeholder?: string;
  type?: string;
  className?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputName: React.FC<InputProps> = (props) => {
  const [isFilled, setIsFilled] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(e);
    setIsFilled(e.target.value.trim().length > 0);
  };

  return (
    <div className={classNames(style.container, props.className)}>
      <input
        className={style.input}
        type={props.type}
        placeholder={props.placeholder}
        onChange={handleInputChange}
      />
      <div className={style.contentContainer}>{isFilled && <CheckIcon />}</div>
    </div>
  );
};
