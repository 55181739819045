// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__JBBzO {
  padding: 12px;
  background-color: #32ade6;
  color: #fff;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1803921569);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
}
.Button_button__JBBzO:active {
  box-shadow: none;
}
.Button_button__JBBzO:hover {
  border: 1px solid #32ade6;
  background-color: #fff;
  color: #32ade6;
}

.Button_disabled__DkgJ\\+ {
  box-shadow: none;
  color: #b9b9ba;
  background-color: #e8e8e9;
  cursor: not-allowed;
}
.Button_disabled__DkgJ\\+:hover {
  border: 1px solid transparent;
  color: #b9b9ba;
  background-color: #e8e8e9;
}`, "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.scss","webpack://./src/scss/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,yBCJK;EDKL,WAAA;EACA,YAAA;EACA,6BAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mDAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,yBAAA;EACA,sBAAA;EACA,cC1BG;ADwBP;;AAMA;EACE,gBAAA;EACA,cC9Ba;ED+Bb,yBChCS;EDiCT,mBAAA;AAHF;AAKE;EACE,6BAAA;EACA,cCpCW;EDqCX,yBCtCO;ADmCX","sourcesContent":["@import \"src/scss/colors\";\n\n.button {\n  padding: 12px;\n  background-color: $blue;\n  color: #fff;\n  height: 50px;\n  border: 1px solid transparent;\n  border-radius: 12px;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 25px;\n  box-shadow: 0 2px 8px 0 #0000002e;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  transition: 0.5s;\n\n  &:active {\n    box-shadow: none;\n  }\n\n  &:hover {\n    border: 1px solid $blue;\n    background-color: #fff;\n    color: $blue;\n  }\n}\n\n.disabled {\n  box-shadow: none;\n  color: $textDisabled;\n  background-color: $disabled;\n  cursor: not-allowed;\n\n  &:hover {\n    border: 1px solid transparent;\n    color: $textDisabled;\n    background-color: $disabled;\n  }\n}\n","$blue: #32ade6;\n$disabled: #e8e8e9;\n$textDisabled: #b9b9ba;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__JBBzO`,
	"disabled": `Button_disabled__DkgJ+`
};
export default ___CSS_LOADER_EXPORT___;
