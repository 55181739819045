import React from "react";

export const ArrowDownIcon = ({ width = "16", height = "16", fill = "black" }) => {
  return (
    <svg
      width={width}
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 11.0508C8.24023 11.0508 8.43945 10.957 8.63281 10.7695L13.0391 6.25781C13.1855 6.10547 13.2676 5.92383 13.2676 5.70703C13.2676 5.26758 12.916 4.91016 12.4824 4.91016C12.2598 4.91016 12.0605 5.00391 11.9023 5.16211L7.99414 9.16992L4.09766 5.16211C3.93945 5.00391 3.73438 4.91016 3.52344 4.91016C3.08398 4.91016 2.73242 5.26758 2.73242 5.70703C2.73242 5.92383 2.80859 6.10547 2.96094 6.25781L7.36719 10.7637C7.55469 10.957 7.75977 11.0508 8 11.0508Z"
        fill={fill}
      />
    </svg>
  );
};
