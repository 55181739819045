import React, { FC } from "react";
import styles from "./Text.module.scss";


const Title = () => {
  return (
    <p className={styles.text}>
        Не знаете что рассказать? Воспользуйтесь нашим генератором вопросов!
    </p>
  );
};
export default Title;
