// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPhoto_addPhotoWrap__ttvBU {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_title__nzY3d {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin: 0 0 24px 0;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_addPhotoContainer__7UbSw {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 8px;
  row-gap: 12px;
  margin-top: 84px;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_addPhotoItem__tcYD\\+ {
  position: relative;
  width: 165px;
  height: 220px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  cursor: pointer;
  overflow: hidden;
  margin: auto;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_iconLabel__zIAyu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_hiddenInputBtn__aAOvm {
  display: none;
}
.AddPhoto_addPhotoWrap__ttvBU .AddPhoto_previewImage__JSc-g {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/components/AddPhoto/AddPhoto.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,aAAA;EACA,sBAAA;AACF;AACE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;AACJ;AAEE;EACE,aAAA;EACA,qCAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;AAAJ;AAGE;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,qBAAA;EACA,eAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAFJ;AAKE;EACE,aAAA;AAHJ;AAME;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,iBAAA;AAJJ","sourcesContent":[".addPhotoWrap {\n  padding: 0 16px;\n  display: flex;\n  flex-direction: column;\n\n  .title {\n    font-size: 24px;\n    font-weight: 400;\n    line-height: 32px;\n    text-align: left;\n    margin: 0 0 24px 0;\n  }\n\n  .addPhotoContainer {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    column-gap: 8px;\n    row-gap: 12px;\n    margin-top: 84px;\n  }\n\n  .addPhotoItem {\n    position: relative;\n    width: 165px;\n    height: 220px;\n    background-color: rgba(255, 255, 255, 1);\n    border-radius: 10px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    align-content: center;\n    cursor: pointer;\n    overflow: hidden;\n    margin: auto;\n  }\n\n  .iconLabel {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    cursor: pointer;\n  }\n\n  .hiddenInputBtn {\n    display: none;\n  }\n\n  .previewImage {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addPhotoWrap": `AddPhoto_addPhotoWrap__ttvBU`,
	"title": `AddPhoto_title__nzY3d`,
	"addPhotoContainer": `AddPhoto_addPhotoContainer__7UbSw`,
	"addPhotoItem": `AddPhoto_addPhotoItem__tcYD+`,
	"iconLabel": `AddPhoto_iconLabel__zIAyu`,
	"hiddenInputBtn": `AddPhoto_hiddenInputBtn__aAOvm`,
	"previewImage": `AddPhoto_previewImage__JSc-g`
};
export default ___CSS_LOADER_EXPORT___;
