// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text_text__Pw1zW {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: rgb(144, 146, 145);
}`, "",{"version":3,"sources":["webpack://./src/components/Text/Text.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,yBAAA;AACF","sourcesContent":[".text {\n  font-size: 15px;\n  font-weight: 400;\n  line-height: 18px;\n  text-align: left;\n  color: rgba(144, 146, 145, 1);\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `Text_text__Pw1zW`
};
export default ___CSS_LOADER_EXPORT___;
