import React from "react";

interface PhotoIconProps {
  className?: string;
}

export const PhotoIcon: React.FC<PhotoIconProps> = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6389 12.5V7.17424C19.6389 6.35859 19.1194 4.72727 17.0417 4.72727H15.5139C15.2083 4.72727 14.5972 4.43939 14.1389 4.00758C13.8333 3.7197 13.3139 3 11.8472 3H8.79167C7.325 3 6.80556 3.7197 6.5 4.00758C6.04167 4.43939 5.43056 4.72727 5.125 4.72727H3.59722C1.51944 4.72727 1 6.35859 1 7.17424V16.3864C1 18.3439 2.73148 18.8333 3.59722 18.8333H12.9167M16.2778 18.8333H23M19.6389 15.6667V22M13.9861 11.4924C13.9861 13.4003 12.3445 14.947 10.3194 14.947C8.2944 14.947 6.65278 13.4003 6.65278 11.4924C6.65278 9.58453 8.2944 8.03788 10.3194 8.03788C12.3445 8.03788 13.9861 9.58453 13.9861 11.4924Z"
      stroke="#98989E"
      stroke-width="1.61321"
      stroke-linecap="round"
    />
  </svg>
);
