import React, { FC } from "react";
import classNames from "classnames";
import styles from "./Button.module.scss";

type ButtonProps = {
  title: string;
  onClick: () => void;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = ({ title, onClick, disabled }) => {
  return (
    <div
      onClick={!disabled ? onClick : undefined}
      className={classNames(styles.button, {
        [styles.disabled]: disabled,
      })}
    >
      {title}
    </div>
  );
};

export default Button;
