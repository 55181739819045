import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Title from "components/Title/Title";
import { InputName } from "components/Input/InputName/InputName";
import { InputDate } from "components/Input/InputDate/InputDate";
import { InputLocation } from "components/Input/InputLocation/InputLocation";
import Button from "components/Button/Button";
import styles from "./RegisterStepOne.module.scss";
import Stepper from "components/Stepper/Stepper";
import Complain from "components/Complain/Complain";


const RegisterStepOne: React.FC = () => {
  const [name, setName] = useState<string | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (name && date && location) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [name, date, location]);

  const handleNextClick = () => {
    navigate("/register-step-two");
  };

  const handleDateChange = (formattedDate: string) => {
    setDate(formattedDate);
  };

  return (
    <div className={styles.registerStepOne}>
      <Stepper currentStep={1} />
      <div className={styles.title}>
        <Title firstTitle="Начнем!" lastTitle="Давайте познакомимся!" />
      </div>
      <div className={styles.fieldsContainer}>
        <InputName
          placeholder="Ваше Имя"
          onChange={(e) => setName(e.target.value)}
        />
        <InputDate onDateChange={handleDateChange} />
        <InputLocation onLocationChange={(value) => setLocation(value)} />
      </div>
     

      <div className={styles.button}>
        <Button
          title="Продолжить"
          onClick={handleNextClick}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  );
};

export default RegisterStepOne;
