import React from "react";
import styles from "./InputAbout.module.scss";
import classNames from "classnames";

interface InputAboutProps {
  text: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputAbout: React.FC<InputAboutProps> = ({ text, onChange }) => {
  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.inputAboutContainer)}>
        <input
          type="text"
          placeholder="Расскажите о себе"
          className={classNames(styles.inputAbout)}
          value={text}
          onChange={onChange}
        />
      </div>
      <div className={classNames(styles.inputWithIcon)}>
        <input
          type="text"
          placeholder="У вас есть домашние питомцы?"
          className={classNames(styles.inputPets)}
        />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(styles.svg)}
        >
          <path
            d="M12.0001 4.5C13.621 4.50062 15.1982 5.02639 16.4953 5.99856C17.7923 6.97072 18.7396 8.33696 19.195 9.89262C19.6505 11.4483 19.5897 13.1096 19.0217 14.6278C18.4538 16.1461 17.4092 17.4394 16.0446 18.3142C14.6799 19.1889 13.0686 19.598 11.4519 19.4801C9.8352 19.3623 8.30022 18.7238 7.07684 17.6604C5.85346 16.597 5.00754 15.1658 4.66578 13.5813C4.32401 11.9968 4.5048 10.3442 5.18107 8.871C5.25468 8.69189 5.25616 8.49126 5.1852 8.31108C5.11424 8.13091 4.97635 7.98516 4.80038 7.90435C4.6244 7.82353 4.42399 7.81391 4.24109 7.8775C4.05818 7.94109 3.90696 8.07295 3.81907 8.2455C3.00759 10.0134 2.79072 11.9966 3.20097 13.8981C3.61121 15.7996 4.62648 17.5169 6.09469 18.793C7.56289 20.069 9.40501 20.835 11.3451 20.9762C13.2852 21.1175 15.2188 20.6263 16.8564 19.5764C18.4939 18.5264 19.7472 16.9742 20.4285 15.1522C21.1098 13.3301 21.1824 11.3364 20.6355 9.46967C20.0886 7.6029 18.9516 5.96357 17.3948 4.79723C15.8381 3.63089 13.9453 3.00033 12.0001 3V4.5Z"
            fill="#98989E"
          />
          <path
            d="M12 6.69864V0.800641C12 0.729386 11.9797 0.659613 11.9414 0.599493C11.9032 0.539372 11.8486 0.491394 11.7841 0.461175C11.7195 0.430956 11.6477 0.419747 11.5771 0.428862C11.5064 0.437977 11.4398 0.467037 11.385 0.512641L7.84504 3.46164C7.80283 3.49683 7.76888 3.54087 7.74558 3.59064C7.72228 3.64041 7.71021 3.69469 7.71021 3.74964C7.71021 3.80459 7.72228 3.85887 7.74558 3.90864C7.76888 3.95841 7.80283 4.00245 7.84504 4.03764L11.385 6.98664C11.4398 7.03224 11.5064 7.06131 11.5771 7.07042C11.6477 7.07953 11.7195 7.06833 11.7841 7.03811C11.8486 7.00789 11.9032 6.95991 11.9414 6.89979C11.9797 6.83967 12 6.7699 12 6.69864Z"
            fill="#98989E"
          />
        </svg>
      </div>
    </div>
  );
};

export default InputAbout;
